import { addApolloState, initializeApollo } from "lib/apolloClient";
import { useQuery } from "@apollo/client";

import Layout from "components/shared/layout/Layout";
import Landing from "components/landing/Landing";

import { GetPage } from "../helpers/api/cadeauConcepten/queries/pages.gql";

function Homepage() {
    const { data } = useQuery(GetPage, {
        variables: {
            slug: "index"
        }
    });

    const fallbackPageMetadata = Object.freeze({
        title: "De Nationale Keuze Cadeaukaart",
        description: `Met de Keuze Cadeaukaart weet je zeker dat het cadeau in de smaak valt.
                          De ontvanger kiest namelijk zelf zijn of haar favoriete cadeaubon,
                          je geeft de keuze uit meer dan 100 opties.`,
        keywords: ["Keuzekaart", "Nationale", "Keuze", "Cadeaukaart"],
        canonical: "https://www.keuzekaart.nl/"
    });

    return (
        <Layout
            metadata={
                data &&
                data?.page &&
                data?.page !== null &&
                data?.page?.metadata &&
                data?.page?.metadata !== null
                    ? data?.page?.metadata
                    : fallbackPageMetadata
            }
        >
            <Landing />
        </Layout>
    );
}

export async function getStaticProps() {
    const apolloClient = initializeApollo();

    // Common product page variables
    const variables = {
        slug: "index",
        pageSize: 17,
        currentPage: 1
    };

    try {
        await apolloClient.query({
            query: GetPage,
            variables
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }

    return addApolloState(apolloClient, { props: {} });
}

export default Homepage;
